




























import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class ViewAskResetPassword extends Vue {
  username = ''

  validate(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.askResetPassword()
    }
  }

  askResetPassword() {
    this.$store.dispatch('askResetPassword', this.username).then(json => {
      this.$store.dispatch('toaster/toast', json)
      if (json.success) {
        this.username = ''
        this.$router.push('login')
      }
    })
  }
}
